import _ from 'lodash'
import store from '@/store'
import { verificationScenarioByRole } from '@/components/utils/common'

export default {
  data () {
    return {
      isLoading: false,
      items: {
        models: [],
        count: 0
      },
      localQuery: {
        options: {}
      }
    }
  },
  watch: {
    localQuery: {
      handler: 'handleQueryChange',
      deep: true
    },
    'localQuery.filter': {
      async handler () {
        await this.handleFilterChange()
      },
      deep: true
    }
  },
  methods: {
    debounceFillData: _.debounce(async function () {
      return this.fillData()
    }, 500),
    async fillData () {
      if (!this.isLoading) { //test:
        this.isLoading = true
        this.items = await this.$store.dispatch('server/get', {
          url: '/' + this.controllerName,
          params: this.localQuery
        }) || { models: [], count: 0 }
        // если указана несуществующая страница - сбрасываем до 1
        if (this.items.count && !this.items.models.length) {
          this.localQuery.options.page = 1
        }
        this.isLoading = false
      }
      return true
    },
    async handleQueryChange (newVal, oldVal) {
      return this.debounceFillData()
    },
    async handleFilterChange () {
      // Проверяем наличие атрибутов кроме разрешенных
      const allowedAttributes = ['eav_entity_id', 'passport_id', 'verificationScenario']
      const hasOtherAttributes = Object.keys(this.localQuery.filter).some(key => !allowedAttributes.includes(key))

      if (hasOtherAttributes) {
        await this.selectAll()
      } else {
        this.objects = []
      }
    },
    async selectAll () {
      if (!this.isLoading) {
        this.isLoading = true
        let passId = this.localQuery.filter.passport_id
        let filter = this.localQuery.filter
        let url = '/object'
        if (passId && Object.keys(filter).length) {
          const response = await store.dispatch('server/get', {
            url: url,
            params: { filter: filter }
          })
          this.objects = response.models || []
          if (!this.items.length && this.objects.length) {
            this.items.count = this.objects.length
            this.items.models = this.objects
          }
        }
        this.isLoading = false
      }
    }
  }
}
