<template>
  <div class="base-header">
    <div class="title-base-header" v-if="$route.name !== 'map'">
      <div v-if="passportEntities"> {{ objectEntityName }}</div>
      <div v-else>{{ this.$route.meta.title }}</div>
    </div>
    <div class="wrapper-base-header">
      <BaseBtn
        v-if="dataBaseDeleteBtn"
        x-small
        icon="mdi-delete"
        is-small-icon
        color="error"
        :title="$t('base.dataBaseDelete')"
        @click="$emit('dataBaseDelete')"
      />
      <BaseBtn
        v-if="recordBtn"
        x-small
        :title="$t('base.record')"
        @click="$emit('record')"
      />
      <BaseBtn
        v-if="filterBtn"
        x-small
        :disabled="disabled"
        icon="mdi-tune-vertical"
        :title="$t('base.filter')"
        @click="$emit('filter')"
      />
      <BaseBtn
        v-if="createBtn"
        color="primary"
        icon="mdi-plus"
        x-small
        :title="$t('base.create')"
        @click="$emit('create')"
      />
      <BaseBtn
        v-if="reportBtn"
        icon="mdi-text-box-multiple"
        x-small
        :title="$t('base.createReport')"
        @click="$emit('report')"
      />
      <BaseBtn
        v-if="reportWithSettings"
        icon="mdi-cog"
        x-small
        :title="$t('base.reportWithSettings')"
        @click="$emit('reportWithSettings')"
      />
      <BaseBtn
        v-if="importBtn"
        icon="mdi-swap-vertical"
        x-small
        :title="$t('base.import')"
        @click="$emit('import')"
      />
      <BaseBtn
        v-if="closeBtn"
        x-small
        color="primary"
        :title="$t('base.close')"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import BaseBtn from '../UI/BaseBtn'

export default {
  name: 'BaseHeader',
  components: { BaseBtn },
  props: {
    passportEntities: Array,
    entityId: Number,
    createBtn: { type: Boolean, default: true },
    reportBtn: { type: Boolean, default: false }, //create report
    importBtn: { type: Boolean, default: false },
    dataBaseDeleteBtn: { type: Boolean, default: false },
    filterBtn: { type: Boolean, default: false },
    recordBtn: { type: Boolean, default: false }, // note: вот это возможно не нужно, надо чекнуть
    disabled: { type: Boolean, default: false },
    closeBtn: { type: Boolean, default: false },
    reportWithSettings: { type: Boolean, default: false }
  },
  computed: {
    objectEntityName () {
      let name = ''
      if (this.entityId) {
        name = this.passportEntities.find(item => item.eavEntity.id === this.entityId)
      } else {
        name = this.passportEntities.find(item => item.eavEntity.id === this.$route.params.entityId * 1)
      }
      return name.eavEntity.entityName
    }
  }
}
</script>

<style scoped>

</style>
