<template>
  <div>
    <BaseHeader
      :passport-entities="passportEntities"
      :entity-id="entityId"
      :create-btn="canCreate"
      :create-report-btn="$can('manager')"
      :import-btn="isImport"
      :data-base-delete-btn="dataBaseDeleteBtn"
      :report-btn="isReport"
      :close-btn="isClose"
      :filter-btn="isFilter"
      :record-btn="isRecord"
      :report-with-settings="reportWithSettings"
      :disabled="isLoading"
      @create="detail"
      @report="$emit('report')"
      @import="$emit('import')"
      @filter="$emit('filter')"
      @record="$emit('record')"
      @close="$emit('close')"
      @dataBaseDelete="$emit('dataBaseDelete')"
      @reportWithSettings="$emit('reportWithSettings')"
      :to="{name:'detail'}"
    />
    <BaseTable
      v-model="selectedItems"
      :loading="isLoading"
      :headers="headers"
      :items="items"
      multi-sort
      :options.sync="localQuery.options"
      :is-loading="isLoading"
      :show-select="showSelect"
      :item-key="itemKey"
      :class="classTable"
      :all-items="allItems"
    >
      <template #item="{item, isSelected}">
        <slot
          name="item"
          :item="item"
          :is-selected="isSelected"
        ></slot>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseHeader from './BaseList/BaseHeader'
import listItem from '@/components/mixins/listItem'
import formatters from '@/components/mixins/formatters'
import list from '@/components/mixins/list'
import BaseTable from '@/components/base/BaseTable'

export default {
  mixins: [list, listItem, formatters],
  name: 'BaseList',
  components: { BaseHeader, BaseTable },
  props: {
    classTable: String,
    passportEntities: Array,
    canCreate: { type: Boolean, default: true },
    entityId: Number,
    reportWithSettings: Boolean,
    isSelectAll: Boolean,
    dataBaseDeleteBtn: { type: Boolean, default: false },
    allItems: Array
  },
  computed: {
    selectedItems: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
