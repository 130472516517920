export default {
  props: {
    item: Object,
    isSelected: Boolean,
    selectable: Boolean,
    canClick: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    localSelected: {
      get () {
        return this.isSelected
      },
      set (val) {
        this.$emit('input', { item: this.item, isSelected: val })
      }
    }
  },
  methods: {
    async detail (id = 'create') {
      return this.canClick ? this.$router.push({ path: this.$route.path + '/detail/' + id }) : true
    }
  }
}
