import { deepEqual, processGetParams } from '@/components/utils/common'
import table from '@/components/mixins/table'

export default {
  mixins: [table],
  props: {
    value: Array,
    headers: Array,
    controller: String,
    query: Object,
    showSelect: Boolean,
    isReport: Boolean,
    isImport: { type: Boolean, default: false },
    isFilter: { type: Boolean, default: false },
    isRecord: { type: Boolean, default: false },
    isClose: { type: Boolean, default: false },
    reportWithSettings: { type: Boolean, default: false },
    itemKey: String
  },
  watch: {
    '$route.fullPath': {
      handler: 'handlePathChange',
      immediate: true
    },
    'query.filter': {
      handler() {
        if (!deepEqual(this.query.filter, this.localQuery.filter)) {
          this.localQuery = { ...this.localQuery, ...this.query }
          //this.handlePathChange()
        }
      },
      deep: true
    }
  },
  methods: {
    async handleQueryChange (newVal, oldVal) {
      if (oldVal && Object.keys(oldVal.options).length) {
        return this.$router.push(
          {
            path: this.$route.path,
            query: processGetParams(this.localQuery)
          },
          async () => {
            return this.debounceFillData()
          },
          async (err) => {
            return this.debounceFillData()
          }
        )
      }
    },
    handlePathChange () {
      if (this.$route.query.pagination) {
        this.localQuery.options.page = this.$route.query.pagination.pageNumber * 1
        this.localQuery.options.itemsPerPage = this.$route.query.pagination.perPage * 1
      }
      if (this.$route.query.sort) {
        for (let attribute in this.$route.query.sort) {
          if (this.$route.query.sort.hasOwnProperty(attribute)) {
            if (!this.localQuery.options.sortBy) {
              this.localQuery.options.sortBy = []
              this.localQuery.options.sortDesc = []
            }
            const attributeIndex = this.localQuery.options.sortBy.findIndex(item => item === attribute)
            if (attributeIndex > -1) {
              this.localQuery.options.sortDesc[attributeIndex] = this.$route.query.sort[attribute] === 'DESC'
            } else {
              this.localQuery.options.sortBy.push(attribute)
              this.localQuery.options.sortDesc.push(this.$route.query.sort[attribute] === 'DESC')
            }
          }
        }
      }
    }
  },
  created () {
    this.localQuery.options = { ...this.localQuery.options, ...this.query }
    this.handlePathChange()
  },
  async mounted () {
    return this.debounceFillData()
  }
}
